.container {
    width: 80%;
    margin: 20px auto;
    overflow: hidden;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
}

.instructions {
    font-family: "Arial", sans-serif;
    color: #333;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.code-block {
    background-color: #f0f2f6; /* Светло-серый фон, характерный для Streamlit */
    color: #666; /* Серый текст для улучшения читаемости */
    padding: 15px;
    border-radius: 5px;
    overflow-x: auto; /* Позволяет горизонтальную прокрутку для длинного кода */
    font-family: "Courier New", Courier, monospace;
}

.code-container {
    position: relative;
    margin-bottom: 10px;
}

.copy-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #4c78a8; /* Голубой, характерный для Streamlit */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
}

.copy-button:hover {
    background-color: #3b5f7a; /* Темнее при наведении */
}

button {
  background-color: #4CAF50; /* Зеленый фон */
  color: white; /* Белый текст */
  padding: 12px 25px; /* Внутренние отступы */
  border: none; /* Убираем границу */
  cursor: pointer; /* Курсор в виде указателя */
  border-radius: 10px; /* Скругленные углы */
  transition: all 0.3s ease; /* Плавный переход для анимации */
  font-size: 16px; /* Размер шрифта */
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Тень для кнопки */
  outline: none; /* Убираем контур при фокусе */
}

button:hover {
  background-color: #45a049; /* Темно-зеленый фон при наведении */
  box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Более заметная тень при наведении */
}

button:active {
  background-color: #3e8e41; /* Еще более темный зеленый при нажатии */
  box-shadow: 0 5px 10px rgba(0,0,0,0.4); /* Тень при нажатии */
  transform: translateY(2px); /* Небольшой сдвиг вниз при нажатии */
}
